// extracted by mini-css-extract-plugin
export var container = "program-module--container--1CguH";
export var blueBtn = "program-module--blueBtn--2RtnG";
export var linkButton = "program-module--linkButton--1YhiT";
export var bgContainer = "program-module--bgContainer--23hZ7";
export var bg = "program-module--bg--2hkmv";
export var image = "program-module--image--2CxMo";
export var contents = "program-module--contents--92HGj";
export var texts = "program-module--texts--3zrd3";
export var textLine = "program-module--textLine--3DDlr";
export var line = "program-module--line--10H5v";
export var whiteBtn = "program-module--whiteBtn--3AIIQ";
export var container2 = "program-module--container2--3cdFd";