// extracted by mini-css-extract-plugin
export var profcertContainer = "Profcert-module--profcertContainer--iGsmG";
export var blueBtn = "Profcert-module--blueBtn--1XsgC";
export var linkButton = "Profcert-module--linkButton--3WJym";
export var initiativesContainer = "Profcert-module--initiativesContainer--23Abu";
export var initiativesList = "Profcert-module--initiativesList--3nFzi";
export var initiativeItem = "Profcert-module--initiativeItem--1keyE";
export var innerContainer = "Profcert-module--innerContainer--1qh3G";
export var imageContainer = "Profcert-module--imageContainer--3F87n";
export var image = "Profcert-module--image--avARk";
export var texts = "Profcert-module--texts--2ro1X";
export var scholarshipContainer = "Profcert-module--scholarshipContainer--1TcDl";
export var containerBg = "Profcert-module--containerBg--NniIW";
export var content = "Profcert-module--content--1sKmf";
export var scholarshipsList = "Profcert-module--scholarshipsList--6HBVC";
export var scholarshipItem = "Profcert-module--scholarshipItem--3BloF";
export var shadow = "Profcert-module--shadow--1fxcZ";
export var bg = "Profcert-module--bg--3CK0t";
export var imgContainer = "Profcert-module--imgContainer--1mP_N";
export var img = "Profcert-module--img--3ruce";
export var link = "Profcert-module--link--u2ZiL";
export var icon = "Profcert-module--icon---jmvj";
export var sponsorContainer = "Profcert-module--sponsorContainer--1QYBt";
export var images = "Profcert-module--images--1QaqC";