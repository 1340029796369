import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import * as styles from './Profcert.module.scss';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';

const Profcert = () => {
    const [screen, setScreen] = useState(null);
    const [hasRan, setHasRan] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            allScholarshipsJson(filter: { level: { eq: "professional" } }) {
                nodes {
                    name
                    slug
                    shortDescription
                    smallImgURL {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    `);

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const handleResize = () => {
        setScreen(getScreenSize());
    };

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getScreenSize]);

    return (
        <div className={styles.profcertContainer}>
            <div className={styles.initiativesContainer}>
                <h5>Who is this for?</h5>

                <div className={styles.initiativesList}>
                    <div className={styles.initiativeItem}>
                        <div className={styles.innerContainer}>
                            <div className={styles.imageContainer}>
                                <StaticImage
                                    src="../images/profcert-who-1.jpg"
                                    alt="Initiative"
                                    className={styles.image}
                                    placeholder="blurred"
                                />
                            </div>

                            <div className={styles.texts}>
                                <h3>Career Launchers</h3>
                                <p>
                                    Fresh graduates that want to start a career
                                    in a high-growth field and be the tech
                                    leader of the next generation.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.initiativeItem}>
                        <div className={styles.innerContainer}>
                            <div className={styles.imageContainer}>
                                <StaticImage
                                    src="../images/profcert-who-2.jpg"
                                    alt="Initiative"
                                    className={styles.image}
                                    placeholder="blurred"
                                />
                            </div>

                            <div className={styles.texts}>
                                <h3>Career Builders</h3>
                                <p>
                                    Working professionals ready to build on
                                    existing digital skills to formalize
                                    training and earn valuable skill
                                    certifications.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.initiativeItem}>
                        <div className={styles.innerContainer}>
                            <div className={styles.imageContainer}>
                                <StaticImage
                                    src="../images/profcert-who-3.jpg"
                                    alt="Initiative"
                                    className={styles.image}
                                    placeholder="blurred"
                                />
                            </div>

                            <div className={styles.texts}>
                                <h3>Career Switchers</h3>
                                <p>
                                    Working professionals looking to switch into
                                    the technology industry, but does not know
                                    where to start.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <Link
                    to="/donate"
                    className={styles.blueBtn}
                    state={{
                        programme:
                            'Professional Certifications & Skills Development'
                    }}
                >
                    Donate To The Cause
                </Link>
            </div>

            <div className={styles.scholarshipContainer}>
                <div className={styles.containerBg}>
                    <div className={styles.content}>
                        <h5>Explore our professional certifications</h5>

                        <div className={styles.scholarshipsList}>
                            {data.allScholarshipsJson.nodes.map(
                                (scholarship) => {
                                    return (
                                        <Link
                                            to={
                                                scholarship.slug ===
                                                'neumann-fellowship'
                                                    ? '/programme/' +
                                                      scholarship.slug
                                                    : '/scholarship/' +
                                                      scholarship.slug
                                            }
                                            style={{ textDecoration: 'none' }}
                                            className={styles.scholarshipItem}
                                        >
                                            <div
                                                className={styles.shadow}
                                            ></div>
                                            <div className={styles.bg}>
                                                <div
                                                    className={
                                                        styles.imgContainer
                                                    }
                                                >
                                                    <GatsbyImage
                                                        image={getImage(
                                                            scholarship.smallImgURL
                                                        )}
                                                        alt={scholarship.name}
                                                        className={styles.img}
                                                        imgStyle={
                                                            screen === 'small'
                                                                ? {
                                                                      borderTopLeftRadius:
                                                                          '10px',
                                                                      borderBottomLeftRadius:
                                                                          '10px'
                                                                  }
                                                                : {
                                                                      borderTopLeftRadius:
                                                                          '10px',
                                                                      borderTopRightRadius:
                                                                          '10px'
                                                                  }
                                                        }
                                                    />
                                                </div>

                                                <div className={styles.texts}>
                                                    <span>
                                                        PROFESSIONAL
                                                        CERTIFICATION
                                                    </span>
                                                    <h6>{scholarship.name}</h6>

                                                    {screen === 'xs' ? null : (
                                                        <p>
                                                            {
                                                                scholarship.shortDescription
                                                            }
                                                        </p>
                                                    )}

                                                    <div
                                                        className={styles.link}
                                                    >
                                                        Learn More
                                                        <HiArrowNarrowRight
                                                            className={
                                                                styles.icon
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.sponsorContainer}>
                <div className={styles.images}>
                    <StaticImage
                        src="../images/profcert-sponsor.jpg"
                        alt="Our Sponsors"
                        className={styles.image}
                        placeholder="blurred"
                        imgStyle={{ borderRadius: '10px' }}
                    />
                    <div className={styles.shadow}></div>
                </div>

                <div className={styles.texts}>
                    <h5>Join our family of corporate sponsors</h5>
                    <p>
                        35 companies have joined our network and provided
                        valuable placement opportunities to more than 156
                        candidates. We are constantly expanding our network with
                        new hiring partners joining us every day. <br />
                        <br />
                        You can be part of the change. <br />
                        Contact us if you’re interested to know more.
                    </p>
                    <Link
                        to="/contact"
                        className={styles.linkButton}
                        state={{
                            message:
                                'Subject: Interested in Being a Corporate Sponsor'
                        }}
                    >
                        <div className={styles.blueBtn}>Contact Us</div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Profcert;
