import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import * as styles from './program.module.scss';
import ProgramInfo from '../components/ProgramInfo';

const Program = ({ data }) => {
    const programme = data.programmesJson;

    return (
        <Layout darkBg={true}>
            <div className={styles.container}>
                <div className={styles.bgContainer}>
                    <div className={styles.bg}>
                        <GatsbyImage
                            image={getImage(programme.imgUrl)}
                            alt={programme.title}
                            className={styles.image}
                        />

                        <div className={styles.contents}>
                            <div className={styles.texts}>
                                <div className={styles.textLine}>
                                    <h6>
                                        {programme.title ===
                                        'Professional Certifications & Skills Development'
                                            ? 'SCHOLARSHIPS'
                                            : 'PROGRAMME'}
                                    </h6>
                                    <div className={styles.line}></div>
                                </div>

                                <h1>{programme.title}</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <ProgramInfo programme={programme.title} />
            </div>
        </Layout>
    );
};

export default Program;

export const programmesQuery = graphql`
    query($slug: String!) {
        programmesJson(slug: { eq: $slug }) {
            imgUrl {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            }
            title
            level
            about
        }
    }
`;
