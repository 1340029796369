import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import * as styles from './K12.module.scss';

const K12 = () => {
    const [screen, setScreen] = useState(null);
    const [hasRan, setHasRan] = useState(false);

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const handleResize = () => {
        setScreen(getScreenSize());
    };

    const data = useStaticQuery(graphql`
        query {
            allStemsubprogrammesJson {
                nodes {
                    about
                    level
                    title
                    imgUrl {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    `);

    const getSubprogrammes = () => {
        var newArr = [...data.allStemsubprogrammesJson.nodes];

        return newArr;
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getScreenSize]);

    return (
        <div className={styles.k12Container}>
            <div className={styles.subprogrammesContainer}>
                <h5>
                    {screen === 'xs'
                        ? 'Explore our K-12 programmes'
                        : 'Inspiring the next generation of digital innovators'}
                </h5>

                {screen === 'xs' ? (
                    ''
                ) : (
                    <p>
                        Careers in science, technology, engineering, and math
                        (STEM) are on the rise and we want the younger
                        generation to be prepared for it. DSF works in
                        collaboration with education providers, and offer a
                        variety of customizable, engaging and industry-relevant
                        STEM courses to K-12 schools.
                    </p>
                )}

                <div className={styles.scholarshipsContainer}>
                    {getSubprogrammes().map((programme) => {
                        return (
                            <div className={styles.scholarship}>
                                <div className={styles.imageContainer}>
                                    <GatsbyImage
                                        alt={programme.title}
                                        image={getImage(programme.imgUrl)}
                                        imgStyle={
                                            screen === 'small'
                                                ? {
                                                      borderBottomLeftRadius:
                                                          '10px',
                                                      borderTopLeftRadius:
                                                          '10px'
                                                  }
                                                : {
                                                      borderTopRightRadius:
                                                          '10px',
                                                      borderTopLeftRadius:
                                                          '10px'
                                                  }
                                        }
                                        className={styles.image}
                                    />
                                </div>
                                <span>{programme.level}</span>
                                <h6>{programme.title}</h6>

                                <p>{programme.about}</p>
                            </div>
                        );
                    })}
                </div>

                <Link
                    to="/donate"
                    className={styles.blueBtn}
                    state={{
                        programme: 'STEM-Focused K-12 Development Programme'
                    }}
                >
                    Donate To The Cause
                </Link>
            </div>

            <div className={styles.subjectsContainer}>
                <h5>
                    {screen === 'xs'
                        ? 'We offer a variety of STEM-focused subjects'
                        : 'Improving digital literacy among K-12 students in STEM-focused subjects'}
                </h5>

                <div className={styles.subjectsList}>
                    <div className={styles.subjectItem}>
                        <StaticImage
                            src="../images/k12-subject-1.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>Programming</span>
                    </div>

                    <div className={styles.subjectItem}>
                        <StaticImage
                            src="../images/k12-subject-2.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>Computer Science</span>
                    </div>

                    <div className={`${styles.subjectItem} ${styles.small}`}>
                        <StaticImage
                            src="../images/k12-subject-3.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>Software Development</span>
                    </div>

                    <div className={`${styles.subjectItem} ${styles.small}`}>
                        <StaticImage
                            src="../images/k12-subject-4.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>Electronics</span>
                    </div>

                    <div className={`${styles.subjectItem} ${styles.small}`}>
                        <StaticImage
                            src="../images/k12-subject-5.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>Robotics</span>
                    </div>

                    <div className={styles.subjectItem}>
                        <StaticImage
                            src="../images/k12-subject-6.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>Drones Technology</span>
                    </div>

                    <div className={styles.subjectItem}>
                        <StaticImage
                            src="../images/k12-subject-7.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>3D Printing</span>
                    </div>

                    <div className={`${styles.subjectItem} ${styles.small}`}>
                        <StaticImage
                            src="../images/k12-subject-8.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>Product Design</span>
                    </div>

                    <div className={`${styles.subjectItem} ${styles.small}`}>
                        <StaticImage
                            src="../images/k12-subject-9.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>Physics & Mechanics</span>
                    </div>

                    <div className={`${styles.subjectItem} ${styles.small}`}>
                        <StaticImage
                            src="../images/k12-subject-10.png"
                            alt="Icon"
                            className={styles.image}
                            placeholder="blurred"
                            objectFit="contain"
                        />

                        <span>General Sciences</span>
                    </div>
                </div>
            </div>

            <div className={styles.contactContainer}>
                <h5>
                    Be a part of the journey of empowering youths in the digital
                    era.
                </h5>
                <p>
                    Are you a course provider, or a learning institution
                    providing education to K-12 students? Join our diverse
                    network of learning institution partners and let's work
                    together to make a difference for the next generation of
                    tech leaders.
                </p>

                <Link
                    to="/contact"
                    className={styles.linkButton}
                    state={{
                        message:
                            'Subject: How to Join the K-12 Development Programme'
                    }}
                >
                    <div className={styles.blueBtn}>Contact Us</div>
                </Link>

                <div className={styles.pictures}>
                    <div className={styles.imageContainer}>
                        <StaticImage
                            src="../images/k12-contact-img-1.jpg"
                            alt="Image"
                            className={styles.image}
                            placeholder="blurred"
                            imgStyle={{ borderRadius: '10px' }}
                        />
                        <div className={styles.shadow}></div>
                    </div>

                    <div className={styles.imageContainer}>
                        <StaticImage
                            src="../images/k12-contact-img-2.jpg"
                            alt="Image"
                            className={styles.image}
                            placeholder="blurred"
                            imgStyle={{ borderRadius: '10px' }}
                        />
                        <div className={styles.shadow}></div>
                    </div>

                    <div className={styles.imageContainer}>
                        <StaticImage
                            src="../images/k12-contact-img-3.jpg"
                            alt="Image"
                            className={styles.image}
                            placeholder="blurred"
                            imgStyle={{ borderRadius: '10px' }}
                        />
                        <div className={styles.shadow}></div>
                    </div>

                    <div className={styles.imageContainer}>
                        <StaticImage
                            src="../images/k12-contact-img-4.jpg"
                            alt="Image"
                            className={styles.image}
                            placeholder="blurred"
                            imgStyle={{ borderRadius: '10px' }}
                        />
                        <div className={styles.shadow}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default K12;
