// extracted by mini-css-extract-plugin
export var k12Container = "K12-module--k12Container--2PiUx";
export var popup = "K12-module--popup--W6J0p";
export var background = "K12-module--background--2dt_H";
export var message = "K12-module--message--2Ndji";
export var close = "K12-module--close--2yyr2";
export var formContainer = "K12-module--formContainer--Na6wA";
export var blueBtn = "K12-module--blueBtn--1BYKU";
export var linkButton = "K12-module--linkButton--2X-Xr";
export var subprogrammesContainer = "K12-module--subprogrammesContainer--1bmi4";
export var scholarshipsContainer = "K12-module--scholarshipsContainer--CHhy6";
export var scholarship = "K12-module--scholarship--1Ro6r";
export var imageContainer = "K12-module--imageContainer--2wuIv";
export var image = "K12-module--image--3ZPgc";
export var subjectsContainer = "K12-module--subjectsContainer--1kGdk";
export var subjectsList = "K12-module--subjectsList--2sHNh";
export var subjectItem = "K12-module--subjectItem--1AEo8";
export var contactContainer = "K12-module--contactContainer--211Vk";
export var pictures = "K12-module--pictures--168h7";
export var shadow = "K12-module--shadow--1Muj6";
export var small = "K12-module--small--mJBYi";