import React from 'react';
import * as styles from './ProgramInfo.module.scss';
import Profcert from './Profcert';
import K12 from './K12';

const ProgramInfo = (props) => {
    const getProgramme = () => {
        if (
            props.programme ===
            'Professional Certifications & Skills Development'
        ) {
            return <Profcert />;
        } else {
            return <K12 />;
        }
    };

    return <div className={styles.programInfoContainer}>{getProgramme()}</div>;
};

export default ProgramInfo;
